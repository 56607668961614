body {
  margin: 0;
  padding: 0;
  font-family: "Open Sans";
  font-weight: 400;
  height: 100%;
  font-size: 14px;
}

html {
  margin: 0;
  padding: 0;
  height: 100%;
}

@keyframes ripple {
  0% {
    box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0);
  }
  50% {
    box-shadow: 0px 0px 0px 8px rgba(0, 0, 0, 0.1);
  }
  100% {
    box-shadow: 0px 0px 0px 8px rgba(0, 0, 0, 0);
  }
}

textarea {
  font-family: inherit;
  font-size: inherit;
}

.Collapsible {
  background-color: white;
  width: 100%;
  box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.14);
}

.Collapsible__contentInner {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 2.5% 2.5%;
}

.Collapsible__trigger {
  height: 58px;
  position: relative;
  padding: 0 2.5%;
  background: white;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.hidden {
  display: none;
}
